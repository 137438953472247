import React from "react"
import Layout from "@/components/layout"
import SucessContent from "@/components/leadsPPAForm"
import Footer from "@/components/footer"

const leadsPPA = () => {
  return (
    <Layout>
      <SucessContent />
      <Footer />
    </Layout>
  );
};

export default leadsPPA;
